import { useState, useEffect } from 'react';
import Path from '../components/Link/Path';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import {
  emailSelector,
  initialsSelector,
  isAdminSelector,
  isManagerSelector,
  nameSelector,
  roleSelector,
  userSelector,
} from '../redux/selectors/user';
import { errorsSelector as errorsArray } from '../redux/selectors/ui';
import { ActionButton } from '../components/Button';
import ColorType from '../components/Theme/ColorType';
import {
  Input,
  PasswordInput,
  SelectInput,
  SwitchStatus,
} from '../components/Input';
import Grid from '@mui/material/Grid';
import {
  DeleteUserAccount,
  updateInfoUser,
  updatePasswordUser,
  verifyUserPhone,
  verifyUserPhoneCode,
} from '../redux/actions/userAction';
import validator from 'validator';
import Avatar from '@mui/material/Avatar';
import PopupConfirmDelete from '../components/Modal/PopupConfirmDelete';
import PopupVerify from '../components/Modal/PopupVerify';
import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { setAxiosHeaders } from '../utils/helper';

type ErrorProp = {
  name?: string;
  language?: string;
  phone?: string;
  new_password?: string;
  confirm_password?: string;
  old_password?: string;
};

const LanguageValues = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
];

const Profile = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const [name, setName] = useState('');
  const [language, setlanguage] = useState('');
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [token, setToken] = useState('');
  const [errors, setErrors] = useState<ErrorProp>({});
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [isSmseNotification, setIsSmseNotification] = useState(false);
  const [isEmailNotification, setIsEmailNotification] = useState(false);

  const errorsSelector = useAppSelector(errorsArray);
  const userName = useAppSelector(nameSelector);
  const userEmail = useAppSelector(emailSelector);
  const userRole = useAppSelector(roleSelector);
  const user = useAppSelector(userSelector);
  const initials = useAppSelector(initialsSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);

  const isInfoUpdated =
    user.name !== name || (user.phone !== phone && isPhoneVerified);
  const isPhoneUpdated = user.phone !== phone;
  const isPasswordUpdate = !(newPassword && confirmPassword && oldPassword);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setPhone(user.phone);
      setIsPushNotification(user.push_notification);
      setIsSmseNotification(user.sms_notification);
      setIsEmailNotification(user.email_notification);
    }
  }, [user]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('lang');
    if (storedLanguage) {
      setlanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    // const msg = 'Invalid old password does not match';
    // if (errorsSelector?.message === msg) setErrors({ oldPassword: msg });
    setErrors(errorsSelector);
    return () => {
      setErrors({});
    };
  }, [errorsSelector]);

  const handleChangeName = (text: string) => {
    setName(text);
  };

  const handleChangePhone = (text: string) => {
    setPhone(text);
  };

  const handleChangePassword = (text: string) => {
    setNewPassword(text);
  };

  const handleChangeConfirmPassword = (text: string) => {
    setConfirmPassword(text);
  };

  const handleChangeOldPassword = (text: string) => {
    setOldPassword(text);
  };

  const validateDataGeneral = () => {
    const errors: ErrorProp = {};
    const phoneValidation = !validator.isMobilePhone(phone);
    if (!name) {
      errors.name = t('name-required');
    }
    if (phoneValidation) {
      errors.phone = t('phone-required');
    }
    return errors;
  };

  const handleSubmitNotification = async () => {
    const data = {
      push_notification: isPushNotification,
      sms_notification: isSmseNotification,
      email_notification: isEmailNotification,
    };
    dispatch(updateInfoUser({ data }));
  };

  const handleSendGeneral = () => {
    const errors = validateDataGeneral();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      name,
      phone,
    };
    dispatch(updateInfoUser({ data }));
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    const passwordValidation = !validator.isStrongPassword(newPassword);
    const confirmPasswordValidation = !(confirmPassword === newPassword);

    if (passwordValidation) {
      errors.new_password = t('reset-error');
    }
    if (confirmPasswordValidation) {
      errors.confirm_password = t('password-missmatch');
    }
    if (!oldPassword) {
      errors.old_password = t('reset-old');
    }
    return errors;
  };

  const handleSendPassword = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    dispatch(updatePasswordUser({ data }));
  };

  const handleOpenDelete = () => setOpen(true);
  const handleCloseDelete = () => setOpen(false);

  const handleOpenPhoneModal = () => setOpenConfirm(true);
  const handleClosePhoneModal = () => setOpenConfirm(false);

  const handleSwitchClick = (
    setItem: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setItem((prev: boolean) => !prev);
  };

  const handleDeleteAccount = () => {
    dispatch(DeleteUserAccount({ handleClose: handleCloseDelete }));
  };

  const handleVerifyPhone = () => {
    const data = {
      type: 'update-phone',
      phone: phone,
    };
    dispatch(verifyUserPhone({ data, handleOpen: handleOpenPhoneModal }));
  };

  const handleValidateChange = () => {
    const data = {
      type: 'update-phone',
      phone: phone,
      token: token,
    };
    dispatch(
      verifyUserPhoneCode({
        data,
        handleClose: handleClosePhoneModal,
        setIsPhoneVerified,
      })
    );
  };

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setlanguage(event.target.value);
    localStorage.setItem('lang', event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Container>
      <HeaderContainer>
        <StyledDiv>
          <Path />
        </StyledDiv>
      </HeaderContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Wrapper>
            <UserContainer>
              <ImageContainer>
                {/* <Image src="https://via.placeholder.com/64x64" /> */}
                <Avatar>{initials}</Avatar>
              </ImageContainer>
              <User>
                <UserWrapper>
                  <UserName>{userName}</UserName>
                  <UserRole>{userEmail}</UserRole>
                  <UserRole>{userRole}</UserRole>
                </UserWrapper>
              </User>
              {!(isAdmin || isManager) && (
                <DeleteContainer>
                  <ActionButton
                    buttonLabel={t('profile-delete-button')}
                    color={'primary'}
                    OnClick={handleOpenDelete}
                  />
                </DeleteContainer>
              )}
              {!(isAdmin || isManager) && (
                <DeleteMobileContainer>
                  <ActionButton
                    buttonLabel=""
                    color={'primary'}
                    OnClick={handleOpenDelete}
                    iconRight={<Delete />}
                  />
                </DeleteMobileContainer>
              )}
            </UserContainer>

            <InformationContainer>
              <InformationText>{t('profile-language-title')}</InformationText>
              <InformationInputWrapper>
                <SelectInput
                  label={t('profile-language-title')}
                  placeholder="HVAC System"
                  values={LanguageValues}
                  value={language}
                  OnChange={handleLanguageChange}
                  error={!!errors?.language}
                  errorMessage={errors?.language}
                />
              </InformationInputWrapper>
            </InformationContainer>

            <InformationContainer>
              <InformationText>{t('profile-general-title')}</InformationText>
              <InformationInputWrapper>
                <Input
                  id="name"
                  label={t('profile-name-title')}
                  placeholder="John Doe"
                  value={name}
                  hasBorder
                  OnChange={handleChangeName}
                  error={!!errors?.name}
                  errorMessage={errors?.name}
                />
              </InformationInputWrapper>
              <InformationInputWrapper>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 8,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <Input
                      label={t('profile-phone-title')}
                      placeholder="+15140000000"
                      value={phone}
                      hasBorder
                      OnChange={handleChangePhone}
                      error={!!errors?.phone}
                      errorMessage={errors?.phone}
                    />
                  </div>
                  <ActionButton
                    buttonLabel={t('profile-verify-button')}
                    color={'primary'}
                    OnClick={handleVerifyPhone}
                    disabled={!isPhoneUpdated}
                    style={{
                      borderRadius: 6,
                      padding: '10px 16px 10px 16px',
                      marginRight: 12,
                      height: 'fit-content',
                      alignSelf: 'flex-end',
                    }}
                  />
                </div>
              </InformationInputWrapper>
              <ActionButton
                buttonLabel={t('profile-update-button')}
                color={'primary'}
                OnClick={handleSendGeneral}
                disabled={!isInfoUpdated}
                style={{
                  borderRadius: 6,
                  padding: '10px 16px 10px 16px',
                  marginRight: 12,
                }}
              />
            </InformationContainer>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Wrapper>
            <GridContainer container>
              <InformationText>
                {t('profile-password-information')}
              </InformationText>
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} item>
                  <PasswordInput
                    label={t('profile-new-password')}
                    id="new-password"
                    placeholder={t('profile-new-password')}
                    value={newPassword}
                    OnChange={handleChangePassword}
                    error={!!errors?.new_password}
                    errorMessage={errors?.new_password}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <PasswordInput
                    label={t('profile-confirm-password')}
                    id="repeat-password"
                    placeholder={t('profile-confirm-password')}
                    value={confirmPassword}
                    OnChange={handleChangeConfirmPassword}
                    error={!!errors?.confirm_password}
                    errorMessage={errors?.confirm_password}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <PasswordInput
                    label={t('profile-current-password')}
                    id="old-password"
                    placeholder={t('profile-current-password')}
                    value={oldPassword}
                    OnChange={handleChangeOldPassword}
                    error={!!errors?.old_password}
                    errorMessage={errors?.old_password}
                  />
                </Grid>
              </Grid>
              <RequirementWrapper>
                <RequirementTitle>
                  {t('profile-password-requirement')}
                </RequirementTitle>
                <RequirementTextContainer>
                  <RequirementText>
                    {t('profile-password-ensure')}
                    <br />
                  </RequirementText>
                  <RequirementText>
                    <li>{t('profile-password-characters')}</li>
                    <li>{t('profile-password-lowercase')}</li>
                    <li>{t('profile-password-uppercase')}</li>
                  </RequirementText>
                </RequirementTextContainer>
              </RequirementWrapper>
              <ActionButton
                buttonLabel={t('profile-password-change')}
                color={'primary'}
                OnClick={handleSendPassword}
                disabled={isPasswordUpdate}
                style={{
                  borderRadius: 6,
                  padding: '10px 16px 10px 16px',
                  marginRight: 12,
                }}
              />
            </GridContainer>
          </Wrapper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Wrapper>
            <InformationContainer>
              <InformationText>
                {t('profile-notification-title')}
              </InformationText>
              <NotificationContainer>
                <span>{t('profile-notification-push')}</span>
                <SwitchStatus
                  checked={!!isPushNotification}
                  handleCheckedChange={() =>
                    handleSwitchClick(setIsPushNotification)
                  }
                />
              </NotificationContainer>
              <NotificationContainer>
                <span>{t('profile-notification-sms')}</span>
                <SwitchStatus
                  checked={!!isSmseNotification}
                  handleCheckedChange={() =>
                    handleSwitchClick(setIsSmseNotification)
                  }
                />
              </NotificationContainer>
              <NotificationContainer>
                <span>{t('profile-notification-email')}</span>
                <SwitchStatus
                  checked={!!isEmailNotification}
                  handleCheckedChange={() =>
                    handleSwitchClick(setIsEmailNotification)
                  }
                />
              </NotificationContainer>
              <ActionButton
                buttonLabel={t('profile-notification-button')}
                color={'primary'}
                OnClick={handleSubmitNotification}
                // disabled={isPasswordUpdate}
                style={{
                  borderRadius: 6,
                  padding: '10px 16px 10px 16px',
                  marginRight: 12,
                }}
              />
            </InformationContainer>
          </Wrapper>
        </Grid>
      </Grid>
      <PopupConfirmDelete
        open={open}
        handleClick={handleDeleteAccount}
        handleClose={handleCloseDelete}
        message={t('profile-popup-title')}
        description={t('profile-popup-description')}
        label={t('profile-popup-confirm')}
        pass={activeLocale === 'en' ? 'DELETE' : 'SUPPRIMER'}
      />

      <PopupVerify
        open={openConfirm}
        handleClick={handleValidateChange}
        handleClose={handleClosePhoneModal}
        token={token}
        setToken={setToken}
      />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
`;

const UserContainer = styled('div')`
  padding: 16px 20px;
  background: ${ColorType.background};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
  border-radius: 10px;
  border: 0.5px ${ColorType.softGrey} solid;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
`;

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const User = styled('div')`
  flex-direction: column;
  gap: 8px;
  display: flex;
`;

const UserWrapper = styled('div')`
  flex-direction: column;
  display: flex;
`;

const UserName = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  @media (max-width: 1169px) {
    font-size: 14px;
  }
`;

const UserRole = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  word-wrap: break-word;
  text-transform: capitalize;
  @media (max-width: 1169px) {
    font-size: 12px;
  }
`;

const InformationContainer = styled('div')`
  padding: 16px 20px;
  background: ${ColorType.background};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
  border-radius: 10px;
  border: 0.5px ${ColorType.softGrey} solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
`;

const NotificationContainer = styled('div')`
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
`;

const InformationText = styled('div')`
  align-self: stretch;
  color: ${ColorType.black};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
const InformationInputWrapper = styled('div')`
  width: -webkit-fill-available;
`;

const RequirementWrapper = styled('div')`
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: flex;
`;
const RequirementTitle = styled('span')`
  align-self: stretch;
  color: #4a4a4a;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;
const RequirementTextContainer = styled('div')`
  align-self: stretch;
`;
const RequirementText = styled('span')`
  color: #4a4a4a;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
`;

const GridContainer = styled(Grid)`
  padding: 16px 20px;
  background: ${ColorType.background};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.04);
  border-radius: 10px;
  border: 0.5px ${ColorType.softGrey} solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
`;

const DeleteContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: 1169px) {
    display: none;
  }
`;

const DeleteMobileContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @media (min-width: 1170px) {
    display: none;
  }
`;

export default Profile;
