import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  open: boolean;
  sensor: any;
  handleClose: () => void;
  handleOpen: () => void;
};

const CheckModal = ({ open, sensor, handleClose, handleOpen }: PopupProps) => {
  const { t } = useTranslation();
  const handleCloseModal = () => {
    handleOpen();
    handleClose();
  };
  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>
        {t('check_message_title')} {sensor?.device_location?.floor}{' '}
        {sensor?.device_location?.floor_area}{' '}
        {sensor?.device_location?.floor_sub_area}
      </DialogTitleContainer>
      <DialogContentContainer>
        <GridContainer container>
          <Grid xs={12} sm={12} item>
            <span
              style={{
                color: '#4A4A4A',
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '20px',
                wordWrap: 'break-word',
              }}
            >
              {t('check_message_body')}
            </span>
          </Grid>
        </GridContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-continue')}
          color={'primary'}
          OnClick={handleCloseModal}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    // width: '25%',
    // maxWidth: '25%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontWeight: 'bold',
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 32,
  paddingBottom: 32,
}));

const GridContainer = styled(Grid)(() => ({
  gap: 15,
  justifyContent: 'center',
}));

export default CheckModal;
